<ng-container *ngIf="viewModel.isAdministrator; else workingHoursTemplate">

  <div fxLayout="row wrap">

    <div
      [fxFlex.gt-sm]="50"
      fxFlex.gt-xs="100"
      fxFlex="100">

      <ct-card [configuration]="masterCardList[0]"></ct-card>

    </div>

    <div
      [fxFlex.gt-sm]="50"
      fxFlex.gt-xs="100"
      fxFlex="100">

      <div *ngFor="let card of cardList">

        <ct-card [configuration]="card"></ct-card>

      </div>

    </div>

  </div>

</ng-container>

<ng-template #workingHoursTemplate>

  <div fxLayout="row wrap">

    <div
      [fxFlex.gt-sm]="50"
      fxFlex.gt-xs="100"
      fxFlex="100">

      <app-user-working-hours-wizard></app-user-working-hours-wizard>

    </div>

  </div>

</ng-template>
