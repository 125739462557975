import {AfterViewInit, Component} from "@angular/core";
import {
  CtBinaryOperator,
  CtControlConfiguration,
  CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelOnFlyCreateService,
  CtModelRouteData,
  CtModelType,
  MateriaHintExtensionConfig, SnackbarService
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {WorkOrderRouteData} from "../../../_core/route-data/work-order.route-data";
import {LocationRouteData} from "../../customer/customer.module";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {BeaverLocation} from "../../../_core/classes/beaver-location";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {CustomerAddressListComponent} from "./customer-address-list/customer-address-list.component";

@Component({
  selector: "app-work-order-edit",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class WorkOrderEditComponent implements AfterViewInit {

  configuration: CtModelConfiguration<WorkOrderEditComponent> | null = null;

  constructor(
    private route: ActivatedRoute,
    private _webapi: CtWebapiService,
    private _snackbar: SnackbarService,
    private dialog: MatDialog
  ) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    const routeData: CtModelRouteData = WorkOrderRouteData()
      .setModelType(CtModelType.FORM);

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) routeData.setId(+pId);

    this.configuration = CtModelConfiguration
      .create<WorkOrderEditComponent>()
      .setRouteData(routeData)
      .setFieldsCustomTemplates([
        {
          prop: "ExtraDataJSON",
          visible: false
        },
        {
          prop: "Address",
          hint: MateriaHintExtensionConfig
            .create()
            .setMessage('Clicca qui per visionare gli indirizzi per il cliente selezionato')
            .setAction(async (control: CtControlConfiguration | null) => {

              const form = control?.formParent;

              if (!form) return;

              const value = form.value.Customer;

              if (!value) {

                this._snackbar.open('Attenzione: non hai selezionato nessun cliente!')
                return

              }

              const address = await this.openCustomerLocationForm(value);

              if (address) {

                form.patchValue({Address: address})

              }

            })

        }
      ]);

  }

  private openCustomerLocationForm(oid: number) {

    return new Promise<string | null>((resolve) => {

      const body: CtModelDatatableOperators = CtModelDatatableOperators
        .create()
        .setFilters([
          CTModelDatatableFilter
            .create()
            .setField('Customer.Oid')
            .setValue(oid)
            .setOperatorType(CtBinaryOperator.Equal)
        ]);

      const request: DataRequest = new DataRequest()
        .setController([LocationRouteData().controller!])
        .setAction('List')
        .setQueryParams({hideSpinner: true})
        .setBody(body);

      this._webapi
        .post<CtModelConfiguration<BeaverLocation>>(request)
        .subscribe({
          next: (result: CtWebapiGenericResponse<CtModelConfiguration<BeaverLocation>>) => {

            if (!(<Array<BeaverLocation>>result.Result.DataSource).length) {

              this._snackbar.open('Attenzione: non hai caricato indirizzi per il cliente selezionato!');
              resolve(null);

            } else {

              this.dialog
                .open(CustomerAddressListComponent, {data: result.Result.DataSource})
                .beforeClosed()
                .subscribe((result) => resolve(result))

            }

          }

        });

    })

  }

}
