import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DashboardComponent} from "./dashboard.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatDividerModule} from "@angular/material/divider";
import {CtCardModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule} from "@angular/flex-layout";
import {WarehouseManagerDashboardComponent} from './warehouse-manager-dashboard/warehouse-manager-dashboard.component';
import {MonitoringManagerDashboardModule} from "./monitoring-manager-dashboard/monitoring-manager-dashboard.module";

@NgModule({
  declarations: [
    DashboardComponent,
    WarehouseManagerDashboardComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    CtCardModule,
    FlexLayoutModule,
    MonitoringManagerDashboardModule
  ]
})
export class DashboardModule {
}
