import {Component} from '@angular/core';
import {NEW_REGISTER_CARD, REGISTER_CARD, WORK_ORDER_CARD} from "./dashboard.monitoring-widget";
import {CtCardConfiguration} from "@ctsolution/ct-framework";
import {Router} from "@angular/router";
import {RoleService} from "../../../_core/lib/role.service";
import {
  UserWorkingHoursWizardService
} from "../../../components/user-working-hours/user-working-hours-wizard/user-working-hours-wizard.service";

@Component({
  selector: 'app-monitoring-manager-dashboard',
  templateUrl: './monitoring-manager-dashboard.component.html',
  styleUrls: ['./monitoring-manager-dashboard.component.scss']
})
export class MonitoringManagerDashboardComponent {

  viewModel = {

    isAdministrator: false

  }

  masterCardList: CtCardConfiguration[] = [
    NEW_REGISTER_CARD.setAction(() => this.userworkinghoursWizardService.open())];

  cardList: CtCardConfiguration[] = [
    REGISTER_CARD.setAction(() => this.router.navigate(["/", "user-working-hours"])),
    WORK_ORDER_CARD.setAction(() => this.router.navigate(["/", "work-order"]))
  ];

  constructor(
    private router: Router,
    private roleService: RoleService,
    private userworkinghoursWizardService: UserWorkingHoursWizardService) {
  }

  ngOnInit() {

    this.setup();

  }

  async setup() {

    this.viewModel.isAdministrator = await this.roleService.is.administrator();

  }

}
