<div fxLayout="row wrap">

  <div *ngFor="let counter of counterList"
       [fxFlex.gt-sm]="100/counterList.length" fxFlex.gt-xs="100" fxFlex="100">

    <ct-card [configuration]="counter"></ct-card>

  </div>

</div>

<div fxLayout="row wrap">

  <div *ngFor="let card of masterCardList"
       [fxFlex.gt-sm]="100/masterCardList.length" fxFlex.gt-xs="100" fxFlex="100">

    <ct-card [configuration]="card"></ct-card>

  </div>

</div>

<mat-divider></mat-divider>

<div fxLayout="row wrap">

  <div *ngFor="let card of cardList"
       [fxFlex.gt-sm]="100/cardList.length" fxFlex.gt-xs="100" fxFlex="100">

    <ct-card [configuration]="card"></ct-card>

  </div>

</div>

<mat-divider></mat-divider>

<div fxLayout="row wrap">

  <div *ngFor="let card of masterCardList2"
       [fxFlex.gt-sm]="100/masterCardList2.length" fxFlex.gt-xs="100" fxFlex="100">

    <ct-card [configuration]="card"></ct-card>

  </div>

</div>
