import {Component, OnInit} from "@angular/core";
import {HandlingController} from "../../../../../beaver-store/src/app/_core/controllers/handling.controller";
import {JwtService} from "../../_core/lib/jwt.service";
import {UserCompanySettings} from "../../_core/classes/user-company-settings";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {

  companySettings: UserCompanySettings | null = null;

  constructor(private jwt: JwtService) {
  }

  ngOnInit() {

    this.setup();

  }

  async setup() {

    this.companySettings = await this.jwt.getUserCompanySettings();

  }

}
