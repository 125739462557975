import {Component} from '@angular/core';
import {
  CtBinaryOperator,
  CtCardConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter
} from "@ctsolution/ct-framework";
import {Router} from "@angular/router";
import {HandlingController} from "../../../_core/controllers/handling.controller";
import {HandlingStateEnum} from "../../../_core/enum/handling-state.enum";
import {
  ENTRY_HANDLING_CARD,
  IMPORT_DATA_CARD, ITEM_CREATE_CARD,
  ITEM_LIST_CARD, MODULE_CREATE_CARD,
  NEW_MOVEMENT_CARD,
  NEW_ORDER_CARD,
  TRANSIT_HANDLING
} from "./dashboard.warehouse-widget";

@Component({
  selector: 'app-warehouse-manager-dashboard',
  templateUrl: './warehouse-manager-dashboard.component.html',
  styleUrls: ['./warehouse-manager-dashboard.component.scss'],
  providers: [HandlingController]
})
export class WarehouseManagerDashboardComponent {

  masterCardList: CtCardConfiguration[] = [
    NEW_MOVEMENT_CARD.setAction(() => this.router.navigate(["/", "handling", "movement", "create"])),
    NEW_ORDER_CARD.setAction(() => this.router.navigate(["/", "handling", "order", "create"]))];
  masterCardList2: CtCardConfiguration[] = [
    IMPORT_DATA_CARD.setAction(() => this.router.navigate(["/", "ct-model", "item-import"]))];
  cardList: CtCardConfiguration[] = [
    ITEM_LIST_CARD.setAction(() => this.router.navigate(["/", "catalog", "item"])),
    ITEM_CREATE_CARD.setAction(() => this.router.navigate(["/", "catalog", "item", "create"])),
    MODULE_CREATE_CARD.setAction(() => this.router.navigate(["/", "catalog", "module", "create"]))];
  counterList: CtCardConfiguration[] = [];

  constructor(
    private router: Router,
    private handlingController: HandlingController) {
  }

  ngOnInit() {

    this.getMovementsInPreparation();
    this.getMovementsInTransit();

  }

  getMovementsInTransit() {

    this.getMovementsByState(HandlingStateEnum.OnTheWay)
      .then(result => this.setupCounter(TRANSIT_HANDLING, result));

  }

  getMovementsInPreparation() {

    this.getMovementsByState(HandlingStateEnum.Draft)
      .then(result => this.setupCounter(ENTRY_HANDLING_CARD, result));

  }

  private setupCounter(counter: CtCardConfiguration, result: CtModelConfiguration<any>) {

    counter
      .setDescription((result?.TotalElement ?? 0) > 0 ? result.TotalElement : "0"); // temporaneo, per mostrare 0 nel pannello

    this.counterList
      .push(counter);

  }

  private getMovementsByState(state: HandlingStateEnum) {

    const filter: CTModelDatatableFilter = CTModelDatatableFilter
      .create()
      .setField("HandlingState")
      .setValue(state)
      .setOperatorType(CtBinaryOperator.Equal);

    return this.handlingController
      .get([filter]);

  }

}
