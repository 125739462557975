import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  UserWorkingHoursWizardModule
} from "../../../components/user-working-hours/user-working-hours-wizard/user-working-hours-wizard.module";
import {MonitoringManagerDashboardComponent} from "./monitoring-manager-dashboard.component";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatDividerModule} from "@angular/material/divider";
import {CtCardModule} from "@ctsolution/ct-framework";


@NgModule({
  declarations: [
    MonitoringManagerDashboardComponent
  ],
  imports: [
    CommonModule,
    UserWorkingHoursWizardModule,
    FlexLayoutModule,
    MatCardModule,
    MatDividerModule,
    CtCardModule,
  ],
  exports: [
    MonitoringManagerDashboardComponent
  ]
})
export class MonitoringManagerDashboardModule {
}
