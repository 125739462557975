<mat-card class="user-working-hours-selected-list-container">

  <mat-card-header>

    <mat-card-title>Riepilogo attività</mat-card-title>

  </mat-card-header>

  <mat-card-content>

    <ng-container
      *ctSkeleton="loading;
           repeat: 3;
           className: 'activity-chip-skeleton';">

      <ng-container *ngIf="list.length; else emptyList">

        <mat-chip-list>

          <mat-chip
            *ngFor="let activity of list"
            [removable]="true"
            (removed)="remove(activity)">
            <mat-icon (click)="notes(activity)" color="accent" matChipAvatar>note_alt</mat-icon>
            <div class="chip-content">{{ activity.Hours }}H {{ activity.ActivityType.Name }} <small
              *ngIf="activity?.WorkOrder?.Name"> ({{ activity?.WorkOrder?.Name }})</small></div>
            <mat-icon matChipRemove color="warn">cancel</mat-icon>
          </mat-chip>

        </mat-chip-list>

      </ng-container>

      <ng-template #emptyList><p>Nessuna attività registrata.</p></ng-template>

    </ng-container>

  </mat-card-content>

</mat-card>
