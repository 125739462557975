export const environment = {
  production: false,
  demo: true,  // pilota la visualizzazione di alcuni componenti solo in modalità demo al cliente; TODO: al momento lo sto usando solo per gestire il barcode tracking, forse meglio abilitare configurazione "BarcodeTrackingEnabled"
  projectName: "VerideaDemo",
  dev: {
    REST_API_SERVER: "https://veridea.demo.api.testctsolution.it/api"
  },
  variables: {
    defaults: {
      inventoryOid: 1
    }
  },
  customTranslationsPath: '/assets/custom-translations.json'
};
