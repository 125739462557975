<ng-container *ngIf="companySettings?.UserHasMonitoringManagementEnabled">

  <app-monitoring-manager-dashboard></app-monitoring-manager-dashboard>

</ng-container>

<ng-container *ngIf="companySettings?.UserHasWarehouseManagementEnabled">

  <app-warehouse-manager-dashboard></app-warehouse-manager-dashboard>

</ng-container>
